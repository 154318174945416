import React from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import cn from 'classnames';

import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModal/BookDemoButton';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';

import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from './startActions.scss';

const StartActions = () => (
  <div className={ styles.actionsWrp }>
    <BookDemoButton className={ cn(styles.actionButton, styles.bookDemoButton) } />
    <span className={ styles.buttonsDivider }>or</span>
    <Link to="/schedule">
      <Button
        variant={ variantTypes.action }
        className={ cn(styles.actionButton, styles.scheduleEventButton, spacesStyles.mrn) }
        title="Schedule Your Event"
        dataTest="scheduleEventButton"
      />
    </Link>
  </div>
);

export default StartActions;
