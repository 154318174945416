import React from 'react';
import Head from 'next/head';
import Intro from './Intro';
import Customers from './Customers';
import Feedbacks from '../Landing/common/Feedbacks';
import Features from './Features';
import GetStarted from '../Landing/common/GetStarted';

const Home = () => (
  <>
    <Head>
      <title>Evercall</title>
      <meta
        name="description"
        content={
          `A secure, reliable and highly professional operator assisted conference call service that specializes in
           capital markets and corporate finance events.`
        }
      />
    </Head>
    <Intro />
    <Customers />
    <Features />
    <Feedbacks />
    <GetStarted />
  </>
);

export default Home;
