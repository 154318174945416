import SharedIntro, { AnimatedTitle } from '@/Framework/UI/Pages/Landing/Sections/Intro';
import StartActions from '../../StartActions';
import video from './assets/video.mp4';
import poster from './assets/poster.webp';

import styles from './intro.scss';

const Intro = () => {
  return (
    <SharedIntro
      className={ styles.intro }
      title={ (
        <>
          <AnimatedTitle
            sliderList={ ['More intelligent', 'More reliable', 'More polished'] }
            className={ styles.introAnimatedTitle }
          />{ ' ' }
          operator assisted conference calls & webinars.
        </>
      ) }
      description={ (
        <p className={ styles.description }>
          Connect with your stakeholders on Evercall, a seamless operator assisted call, webinar and meeting provider
          that specializes in capital markets and corporate finance events. It’s backed by fanatical 24x7 support and
          instant scheduling with guaranteed availability.*
        </p>
      ) }
      video={ video }
      // @ts-ignore
      poster={ poster }
      actionButtons={ <StartActions /> }
    />
  );
};

export default Intro;
