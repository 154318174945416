import { Link } from '@/Framework/Router/Next/Link';
import FeaturesComponent from '@/evercall/ui/common/Features';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import styles from '@/evercall/ui/common/Features/features.scss';

const Features = () => (
  <FeaturesComponent
    title="Everything you need for flawless execution."
    description="Evercall is faster to set up, staffed by rigorously trained moderators and offers a seamless experience for thousands of participants. When you need to put your best foot forward, use Evercall."
    actionButton={ (
      <Link to="/features">
        <Button
          variant={ variantTypes.action }
          className={ styles.actionButton }
          title="Explore Our Features"
          dataTest="exploreOurFeaturesButton"
        />
      </Link>
    ) }
  />
);

export default Features;
